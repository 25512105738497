import {Pipe, PipeTransform} from '@angular/core';
import {FacilityChooserService} from '../../@core/facility-chooser.service';
import {SimpleDatePipe} from './simple-date.pipe';
import {toDateTime} from '../../utils/date.utils';
import {DateTime} from 'luxon';

interface PeriodPipeOptions {
    startKey?: string;
    endKey?: string;
    needsTimezone?: boolean;
}

const defaultPeriodOptions: PeriodPipeOptions = {
    startKey: 'start_date',
    endKey: 'end_date',
    needsTimezone: true,
};

@Pipe({
    name: 'period',
})
export class PeriodPipe implements PipeTransform {
    transform(value: any, options?: PeriodPipeOptions): string | null {
        return PeriodPipe.transform(value, options);
    }

    static transform(value: any, options?: PeriodPipeOptions): string | null {
        if (!value) return '';
        const opts = {...defaultPeriodOptions, ...options};
        const start = toDateTime(value[opts.startKey], {zone: opts.needsTimezone ? FacilityChooserService.timezone: null});
        const end = toDateTime(value[opts.endKey], {zone: opts.needsTimezone ? FacilityChooserService.timezone : null});

        const startYear = start?.year;
        const endYear = end?.year;
        const currYear = DateTime.now().year;
        const sameYear = startYear == endYear;
        const sameMonth = sameYear && start?.month === end?.month;

        if (sameYear && sameMonth && start?.equals(end)) return SimpleDatePipe.transform(value[opts.startKey]);

        const startTxt = startYear ? `${start.toFormat('MMM')} ${start.toFormat('d')}${sameYear || (!endYear && startYear == currYear) ? '' : `, ${startYear}`}` : '';
        const endTxt = endYear ? `${sameMonth ? '' : `${end.toFormat('MMM')} `}${end.toFormat('d')}${endYear == currYear ? '' : `, ${endYear}`}` : '';

        return `${startTxt} - ${endTxt}`;
    }
}
