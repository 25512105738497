<div class="body-dropdown-wrapper" (clickOutside)="datepickerOpen && close()" #dropdownWrapper>
    <div [class]="controlClass || 'body-select'" (click)="datepickerOpen ? close() : open()">
        <fal *ngIf="icon" i="calendar" class="icon"></fal>
        <span class="caption"
              [class.text-placeholder]="!dateCaption">
            {{dateCaption || placeholder}}
        </span>
        <span *ngIf="clearable && dateCaption"
              title="Clear"
              class="datepicker-select-clear-wrapper icon"
              (click)="clear()">
            <span class="datepicker-select-clear">×</span>
        </span>
    </div>
    <div class="body-dropdown-content" [hidden]="!datepickerOpen">
        <div class="d-flex">
            <div>
                <ngb-datepicker-range
                    [dates]="{start_date: dateFilter?.start_date, end_date: dateFilter?.end_date}"
                    (datesChange)="onDatesChange($event)"
                    [initInterval]="initInterval"
                    [needsTimezone]="needsTimezone"
                    [displayMonths]="displayMonths"
                    [format]="format">
                </ngb-datepicker-range>
                <div class="p-3" *ngIf="hasIntervals" class="ui-section">
                    <span *ngFor="let interval of intervals; let i = index" class="mr-3" [class.disabled]="interval.disabled">
                        <input type="radio" name="filter_interval" id="interval_{{i}}"
                               [checked]="dateFilter.interval == interval.key"
                               (change)="dateFilter.interval = interval.key; onIntervalChange()"
                               [disabled]="interval.disabled">
                        <label for="interval_{{i}}" [class.disabled]="interval.disabled">{{interval.value}}</label>
                    </span>
                </div>
                <div *ngIf="hasComparison" class="ui-section">
                    Compare to:
                    <select *ngIf="hasComparisonMultiplier" [(ngModel)]="dateFilter.comparison_period_count" (ngModelChange)="updated = true" class="body-select">
                        <option *ngFor="let x of [0, 1, 2, 3, 4, 5, 6]" [value]="x">{{x}} x</option>
                    </select>
                    <select [(ngModel)]="dateFilter.comparison_period" (ngModelChange)="updated = true" class="body-select">
                        <option *ngFor="let cp of comparisonPeriods" [value]="cp.key">{{cp.value}}</option>
                    </select>
                </div>
            </div>
            <div class="shortcuts">
                <h6 class="heading">Select</h6>
                <div *ngFor="let p of periods" class="item"><a (click)="dpRange.setInterval(p.key)" tabindex="-1">{{p.value}}</a></div>
            </div>
        </div>
    </div>
</div>
