import {takeUntil} from 'rxjs/operators';
import {Component, Output, EventEmitter, OnInit, Input, OnDestroy} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FacilityChooserService} from '../../../../@core/facility-chooser.service';
import {Subject} from 'rxjs';
import {fromNgbDateStructTo, toNgbDateStructFrom, NgbDateTimeStruct, fromDateTimeToFormat} from '../../../../utils/date.utils';
import {DateTime} from 'luxon';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
    one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ?
        false :
        one.year === two.year ?
            one.month === two.month ?
                one.day === two.day ?
                    false :
                    one.day < two.day :
                one.month < two.month :
            one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ?
        false :
        one.year === two.year ?
            one.month === two.month ?
                one.day === two.day ?
                    false :
                    one.day > two.day :
                one.month > two.month :
            one.year > two.year;

export interface DatepickerRangeInterface {
    start_date: Date | NgbDateStruct | NgbDateTimeStruct | string;
    end_date: Date | NgbDateStruct | NgbDateTimeStruct | string;
    timezone?: string;
}

@Component({
    selector: 'ngb-datepicker-range',
    templateUrl: './datepicker-range.component.html',
    styleUrls: ['./datepicker-range.component.scss'],
})
export class NgbDatepickerRange implements OnInit, OnDestroy {
    @Input() dates: DatepickerRangeInterface;
    @Output() datesChange = new EventEmitter<DatepickerRangeInterface>();

    @Input() format: 'date' | 'ngbDate' | 'text' = 'date';
    @Input() initInterval = 'THIS_MONTH';
    @Input() needsTimezone = false;
    @Input() displayMonths = 2;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    timezone = '';

    private ngUnsubscribe = new Subject<void>();

    constructor(private fc: FacilityChooserService) {
        this.timezone = this.fc.timezone;
    }

    ngOnChanges() {
        if (this.format === 'text') this.needsTimezone = false;
    }

    ngOnInit() {
        if (this.dates?.start_date) {
            if (this.dates.start_date instanceof Date) this.format = 'date';
            this.fromDate = this.toNgbDateStructFrom[this.format](this.dates.start_date as any);
            this.toDate = this.dates.end_date ? this.toNgbDateStructFrom[this.format](this.dates.end_date as any) : null;
        } else {
            this.setInterval(this.initInterval);
        }
        if (this.needsTimezone) {
            this.fc.getSilently().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                if (this.fromDate && this.toDate && this.fc.timezone != this.timezone) {
                    this.timezone = this.fc.timezone;
                    this.updateDates();
                }
            });
        }
    }

    setInterval(interval: string) {
        let from = this.needsTimezone ? DateTime.now().setZone(this.fc.timezone) : DateTime.now(),
            to = this.needsTimezone ? DateTime.now().setZone(this.fc.timezone) : DateTime.now();
        switch (interval) {
            case 'NONE':
                from = null;
                to = null;
                break;
            case 'TODAY':
                from = from.startOf('day');
                to = to.endOf('day');
                break;
            case 'YESTERDAY':
                from = from.minus({days: 1}).startOf('day');
                to = to.minus({days: 1}).endOf('day');
                break;
            case 'LAST_3_DAYS':
                from = from.minus({days: 2}).startOf('day');
                to = to.endOf('day');
                break;
            case 'LAST_7_DAYS':
                from = from.minus({days: 6}).startOf('day');
                to = to.endOf('day');
                break;
            case 'THIS_WEEK':
                from = from.startOf('week');
                to = to.endOf('week');
                break;
            case 'LAST_WEEK':
                from = from.minus({weeks: 1}).startOf('week');
                to = to.minus({weeks: 1}).endOf('week');
                break;
            case 'LAST_30_DAYS':
                from = from.minus({days: 29}).startOf('day');
                to = to.endOf('day');
                break;
            case 'THIS_MONTH':
                from = from.startOf('month');
                to = to.endOf('month');
                break;
            case 'LAST_MONTH':
                from = from.minus({months: 1}).startOf('month');
                to = to.minus({months: 1}).endOf('month');
                break;
            case 'LAST_90_DAYS':
                from = from.minus({days: 89}).startOf('day');
                to = to.endOf('day');
                break;
            case 'THIS_QUARTER':
                from = DateTime.now().startOf('quarter');
                to = DateTime.now().endOf('quarter');
                break;
            case 'LAST_QUARTER':
                from = DateTime.now().startOf('quarter').minus({quarters: 1});
                to = DateTime.now().endOf('quarter').minus({quarters: 1});
                break;
            case 'LAST_183_DAYS':
                from = from.minus({days: 182}).startOf('day');
                to = to.endOf('day');
                break;
            case 'LAST_365_DAYS':
                from = from.minus({days: 364}).startOf('day');
                to = to.endOf('day');
                break;
            case 'THIS_YEAR':
                from = from.startOf('year');
                to = to.endOf('year');
                break;
            case 'LAST_YEAR':
                from = from.minus({years: 1}).startOf('year');
                to = to.minus({years: 1}).endOf('year');
                break;
        }
        if (from && to) {
            this.fromDate = this.toNgbDateStructFrom.dateTime(from);
            this.toDate = this.toNgbDateStructFrom.dateTime(to);
            // Fix for ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                this.updateDates();
            }, 0);
        }
    }

    get toNgbDateStructFrom() {
        return toNgbDateStructFrom(false, this.needsTimezone ? this.fc.timezone : null);
    }

    get fromNgbDateStructTo() {
        return fromNgbDateStructTo(false, this.needsTimezone ? this.fc.timezone : null);
    }

    onDateChange(date: NgbDateStruct) {
        if (this.fromDate && (!this.toDate || this.toDate == this.fromDate) && after(date, this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = date;
            this.fromDate = date;
        }
        this.updateDates();
    }

    updateDates() {
        this.dates = {
            ...(this.dates || {}),
            start_date: this.fromNgbDateStructTo[this.format](this.fromDate),
            end_date: fromDateTimeToFormat(this.format !== 'text', this.needsTimezone ? this.fc.timezone : null, false)[this.format](this.fromNgbDateStructTo.dateTime(this.toDate).endOf('day')),
        };
        if (this.needsTimezone) this.dates.timezone = this.timezone;
        this.datesChange.emit(this.dates);
    }

    isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
    isInside = date => after(date, this.fromDate) && before(date, this.toDate);
    isFrom = date => equals(date, this.fromDate);
    isTo = date => equals(date, this.toDate);

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
