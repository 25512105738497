<ngb-datepicker #dp
                ngModel
                (ngModelChange)="onDateChange($event)"
                [displayMonths]="displayMonths"
                [dayTemplate]="t"
                [minDate]="{year: 1900, month: 1, day: 1}">
</ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
